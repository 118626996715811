export default class Session {
  constructor(
    club,
    trialsTasterWelcome,
    description,
    dayDate,
    startTime,
    endTime,
    mins,
    facility,
    callToAction,
    homePage,
    levelOfAbility,
    notes
  ) {
    this._callToAction = callToAction.trim();
    this._dayDate = dayDate.trim();
    this._endTime = endTime.trim();
    this._homePage = homePage.trim();
    this._mins = mins.trim();
    this._notes = notes.trim();
    this._startTime = startTime.trim();
    this.club = club.trim();
    this.description = description.trim();
    this.facility = facility.trim();
    this.levelOfAbility = levelOfAbility.trim();
    this.trialsTasterWelcome = trialsTasterWelcome.trim();
  }

  get eventDate() {
    if (!!this._startTime && !!this._endTime) {
      return `${this._dayDate} ${this._startTime}-${this._endTime}`;
    } else if (!!this._startTime) {
      return `${this._dayDate} ${this._startTime}`;
    } else {
      return `${this._dayDate}`;
    }
  }

  get callToAction() {
    return this._callToAction.replace(
      /(^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$)/,
      "<a href='$1' target='_blank'>Sign-up form</a>"
    );
  }

  get visible() {
    return "live" === this._homePage.toLowerCase();
  }
}
