export default class Sport {
  sessions = []; // Session[]

  constructor(name) {
    this.name = name;
  }

  get uniqid() {
    const uniqid = this.name
      .toLowerCase()
      .replaceAll("&", "")
      .replaceAll(" ", "");
    return uniqid;
  }

  addSession(session) {
    if (!session.visible) {
      return 0;
    }

    return this.sessions.push(session);
  }

  generateTable(bootstrapClasses = false) {
    let html = "";
    html += `
<div class="table-wrapper has-scroll">
  <div class="table-scroller">
    <table${!bootstrapClasses ? "" : ' class="table table-striped"'}>
      <thead>
        <tr>
          <th>Session type</th>
          <th>Session detail</th>
          <th>Ability</th>
          <th>Date</th>
          <th>Location</th>
          <th>Call to action</th>
        </tr>
      </thead>
      <tbody>`;

    for (let index = 0; index < this.sessions.length; index++) {
      const session = this.sessions[index];
      html += `
        <tr>
          <td>${session.trialsTasterWelcome}</td>
          <td>${session.description}</td>
          <td>${session.levelOfAbility}</td>
          <td>${session.eventDate}</td>
          <td>${session.facility}</td>
          <td>${session.callToAction}</td>
        </tr>`;
    }

    html += `
      </tbody>
    </table>
  </div>
</div>`;

    return html;
  }

  generateTextArea(content) {
    let html = "";
    html += `
    <label for="${this.name}">${this.name}</label><br />`;

    html += `<textarea onclick="copyText${this.uniqid}()" id="${this.name}" name="${this.name}" rows="4" cols="50">${content}</textarea>`;

    return html;
  }

  getContent() {
    const title = `<h2 style="margin-top: 2rem;">${this.name}</h2>`;
    const styledTable = this.generateTable(true);
    const table = this.generateTable();
    const textArea = this.generateTextArea(table);
    const js = this.getJS();
    return title + styledTable + textArea + js;
  }

  getJS() {
    let html = "";
    html += `<script>`;
    html += `
function copyText${this.uniqid}() {
  var copyText = document.getElementById("${this.name}");

  copyText.select();
  copyText.setSelectionRange(0, 99999); // For mobile devices
  
  navigator.clipboard.writeText(copyText.value);
  
  // alert("Copied the text: " + copyText.value);
  copyText.after('Copied.');
}
`;
    html += `</script>`;
    return html;
  }
}
