import Session from "./Session.js";
import Sport from "./Sport.js";
import SportRepository from "./SportRepository.js";
import Papa from "papaparse";

docReady(function () {
  document
    .getElementById("fileinput")
    .addEventListener("change", readSingleFile, false);
});

function convertCSVtoHTML(csvData) {
  try {
    const parsedData = parseCSV(csvData);
    const sportRepo = new SportRepository();

    for (let index = 0; index < parsedData.length; index++) {
      const row = parsedData[index];
      const session = new Session(...Object.values(row));
      let sport = sportRepo.getSport(session.club);
      if (!sport) {
        sport = new Sport(session.club);
        sportRepo.addSport(sport);
      }
      sport.addSession(session);
    }

    let content = "";
    sportRepo.sports.forEach((sport) => {
      content += sport.getContent();
    });
    return content;
  } catch (err) {
    console.error("Error processing CSV:", err);
  }
}

function parseCSV(csvData) {
  let parsedData;

  Papa.parse(csvData, {
    complete: function (results) {
      parsedData = results.data;
    },
  });

  let $i = 0;
  while ($i < 4) {
    parsedData.shift();
    $i++;
  }

  return parsedData;
}

function readSingleFile(evt) {
  //Retrieve the first (and only!) File from the FileList object
  var f = evt.target.files[0];

  if (f) {
    var r = new FileReader();
    r.onload = function (e) {
      var contents = e.target.result;
      document.getElementById("output").innerHTML = convertCSVtoHTML(contents);
    };
    r.readAsText(f);
  } else {
    alert("Failed to load file");
  }
}

function docReady(fn) {
  // see if DOM is already available
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}
