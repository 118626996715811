export default class SportRepository {
  sports = new Map();

  addSport(sport) {
    if (this.sports.has(sport.name)) {
      return false;
    }

    return this.sports.set(sport.name, sport);
  }

  getSport(sportName) {
    return this.sports.get(sportName);
  }
}
